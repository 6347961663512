import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = forwardRef(
  (
    {
      label = null,
      description = null,
      icon = null,
      type = 'text',
      error = null,
      errorMessage = null,
      errorLabelOverflow = false,
      persistentPlaceHolder = '',
      inputHelper = '',
      maxLength = 30,
      ...rest
    },
    ref
  ) => {
    const [secureTextEntry, setSecureTextEntry] = useState(type === 'password');

    const handleChangeSecureEntry = () => {
      setSecureTextEntry((state) => !state);
    };

    const handleInputType = () => {
      if (type === 'password') {
        if (secureTextEntry) {
          return 'password';
        }
        return 'text';
      }
      return type;
    };

    const inputWrapperClasses = classNames(
      'input-group poppins-400 f-16',
      {
        error,
      },
      rest.className
    );

    const inputGroupClasses = classNames('input', {
      error,
      'error-message': errorMessage,
    });

    const inputClasses = classNames({
      error,
      'input-side-icon': icon,
    });

    const inputLabelError = classNames({
      error,
      'label-overflow-container': errorLabelOverflow,
    });

    return (
      <div className={inputWrapperClasses}>
        {(label !== null || description !== null) && (
          <div className="input-text">
            {label !== null && <label>{label}</label>}&nbsp;
            {description !== null && <p>{description}</p>}
          </div>
        )}
        <div className={inputGroupClasses}>
          <input
            className={inputClasses}
            type={handleInputType()}
            maxLength={maxLength}
            ref={ref}
            {...rest}
          />
          {icon && <span className="material-icons">{icon}</span>}

          {persistentPlaceHolder && (
            <p className="f-16 poppins-400">{persistentPlaceHolder}</p>
          )}

          {type === 'password' && (
            <span
              role="presentation"
              className="material-icons-outlined input-icon password-icon"
              onClick={handleChangeSecureEntry}
            >
              {secureTextEntry ? 'visibility_on' : 'visibility_off'}
            </span>
          )}
          {errorMessage && (
            <label className={inputLabelError}>{errorMessage}</label>
          )}
          {inputHelper && !errorMessage && (
            <label className="input-helper">{inputHelper}</label>
          )}
        </div>
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  errorLabelOverflow: PropTypes.bool,
  persistentPlaceHolder: PropTypes.string,
  inputHelper: PropTypes.string,
};

export default Input;
