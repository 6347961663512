import React from 'react';

import PropTypes from 'prop-types';

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

import './style.scss';
import Navbar from '../Navbar';

const PageWrapper = ({ user, railsContext, children }) => {
  const honeybadger = Honeybadger.configure(railsContext.honeybadgerConfig);

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      {!user && <Navbar />}
      {children}
    </HoneybadgerErrorBoundary>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  railsContext: PropTypes.shape({
    honeybadgerConfig: PropTypes.shape({
      apiKey: PropTypes.string,
      environment: PropTypes.string,
      projectRoot: PropTypes.string,
      revision: PropTypes.string,
    }),
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

export default PageWrapper;
