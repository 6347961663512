import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useDroppable } from '@dnd-kit/core';

import classNames from 'classnames';

import Logo from '../Logo/logo.svg';

import Button from '../Button';

import Icon from '../Icon';

import Settings from '../Settings';

import { signOutUserRequest } from '../../api/authentication';

const Navbar = ({
  user,
  dashboardPage = false,
  statisticsPage = false,
  handleAddCarapine,
  handleDeleteCarapine,
  onManageFlavors,
  showDeleteButton,
  initialSettings,
}) => {
  const [showSettings, setShowSettings] = useState(false);

  const { setNodeRef, isOver } = useDroppable({
    id: 'trash',
  });

  return (
    <nav className="d-flex">
      {user ? (
        <div className="nav-buttons d-flex w-100 justify-content-start align-items-center">
          {dashboardPage && (
            <>
              {showDeleteButton ? (
                <div className="h-auto w-auto nav-center" ref={setNodeRef}>
                  <Button
                    label=""
                    icon="delete_outline"
                    className={classNames(
                      'img-button',
                      'color-secondary-red',
                      'delete-carapine-button',
                      isOver && 'over'
                    )}
                    onClick={handleDeleteCarapine}
                  />
                </div>
              ) : (
                <>
                  <Button
                    className="text-initial add-button"
                    label="Criar carapina"
                    onClick={() => handleAddCarapine()}
                  />
                  <Button
                    secondary
                    className="text-initial"
                    label="Gerir sabores"
                    onClick={() => onManageFlavors()}
                  />
                  <Button
                    secondary
                    className="text-initial"
                    label="Estatísticas"
                    as="a"
                    href="/statistics"
                  />
                </>
              )}
            </>
          )}
          {statisticsPage && (
            <>
              <Button
                secondary
                className="text-initial"
                label="Voltar"
                icon="arrow_back_ios"
                as="a"
                href="/"
              />
              <h1 className="nav-center poppins-600 f-24 color-primary-green">
                Estatísticas de Venda
              </h1>
            </>
          )}
          <Icon
            className="ms-auto icon-nav"
            name="settings"
            transparent
            rounded
            onClick={() => setShowSettings(true)}
          />
          <Icon
            className="icon-nav"
            name="logout"
            rounded
            onClick={signOutUserRequest}
          />
          {showSettings && (
            <Settings
              initialSettings={initialSettings}
              onClose={() => setShowSettings(false)}
            />
          )}
        </div>
      ) : (
        <div className="d-flex w-100 justify-content-center">
          <a href="/" className="d-flex cursor-pointer">
            <img src={Logo} alt="Doppo Logo" />
          </a>
        </div>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  dashboardPage: PropTypes.bool,
  statisticsPage: PropTypes.bool,
  handleAddCarapine: PropTypes.func,
  handleDeleteCarapine: PropTypes.func,
  onManageFlavors: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  initialSettings: PropTypes.object,
};

export default Navbar;
