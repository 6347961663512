import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import updateSettings from '../../api/settings';

import Modal from '../Modal';
import Button from '../Button';
import NumericInput from '../NumericInput';
import Alert from '../Alert';

const Settings = ({ initialSettings, onClose }) => {
  const [errorAlert, setErrorAlert] = useState(null);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialSettings,
  });

  useEffect(() => {
    setFocus('default_weight_carapine', { shouldSelect: true });
  }, [setFocus]);

  const weightProps = register('default_weight_carapine', {
    required: {
      value: true,
      message: 'O peso é obrigatório',
    },
    pattern: {
      value: /^\d*$/,
      message: 'O peso tem de ser um número',
    },
    max: {
      value: 9000,
      message: 'O peso não deve exceder os 9000g',
    },
  });

  const onSubmit = async (data) => {
    try {
      await updateSettings(data);
      onClose();
    } catch (except) {
      setErrorAlert(
        'Erro de comunicação. Por favor volte a tentar mais tarde.'
      );
    }
  };

  const invalidMessage = errors.default_weight_carapine?.message;

  return (
    <Modal onClose={onClose} className="settings" closeIcon visible>
      <h1 className="title">Definições</h1>
      {errorAlert && (
        <Alert type="danger" className="w-100">
          {errorAlert}
        </Alert>
      )}
      <form className="settings-form" onSubmit={handleSubmit(onSubmit)}>
        <NumericInput
          {...weightProps}
          label="Peso Carapina"
          persistentPlaceHolder="g"
          error={invalidMessage}
          errorMessage={invalidMessage}
        />
        <Button type="submit" className="submit-btn" label="Guardar" />
      </form>
    </Modal>
  );
};

Settings.propTypes = {
  initialSettings: PropTypes.object,
  onClose: PropTypes.func,
};

export default Settings;
