import { getUrlParam } from '../utils/url';

import client from './client';

const signInUserRequest = async ({ email, password }) => {
  await client.post('/users/sign_in', {
    user: {
      email,
      password,
    },
  });
};

const signOutUserRequest = async () => {
  await client
    .delete('/users/sign_out')
    .then(() =>
      window.location.assign(`${window.location.origin}${'/users/sign_in'}`)
    )
    .catch((err) => console.log(err));
};

const resetPasswordRequest = async ({ email }) => {
  await client.post('/users/password', {
    user: {
      email,
    },
  });
};

const editPasswordRequest = async ({ password, confirmPassword }) => {
  await client.patch('/users/password', {
    user: {
      password,
      confirmPassword,
      resetPasswordToken: getUrlParam('reset_password_token'),
    },
  });
};

export {
  signInUserRequest,
  signOutUserRequest,
  resetPasswordRequest,
  editPasswordRequest,
};
