import client from './client';

const updateSettings = async (data) => {
  return client.patch(`/settings/`, {
    setting: {
      defaultWeightCarapine: data.default_weight_carapine,
    },
  });
};

export default updateSettings;
