import React, { useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

const Icon = ({
  name,
  className,
  rounded = false,
  size = 'md',
  primary = false,
  secondary = false,
  transparent = false,
  danger = false,
  tooltip = null,
  type = '',
  as: Component = 'button',
  ...rest
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleTooltipVisibility = (visibility) => {
    if (tooltip !== null) setIsTooltipVisible(visibility);
  };

  const iconButtonClasses = classNames(
    'icon-button',
    {
      /* size */
      [`size-${size}`]: size,
      rounded,

      /*  background */
      primary: primary && !transparent,
      secondary: secondary && !transparent,
      danger: danger && !transparent,
      transparent,
    },
    className
  );

  let materialIconsType = 'material-icons';
  if (type !== '') materialIconsType += `-${type}`;

  return (
    <Component
      type="button"
      className={iconButtonClasses}
      onMouseEnter={() => handleTooltipVisibility(true)}
      onMouseLeave={() => handleTooltipVisibility(false)}
      {...rest}
    >
      <span className={materialIconsType}>{name}</span>
      {tooltip !== null && isTooltipVisible && (
        <div className="tooltip">{tooltip}</div>
      )}
    </Component>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  transparent: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['outlined', 'round', 'sharp', 'two-tone']),
  as: PropTypes.node,
};

export default Icon;
