import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';

const Alert = ({ type = 'info', className, noIcon, children }) => {
  return (
    <div className={classNames('alert', type, className)}>
      {!noIcon && (
        <Icon
          as="div"
          className="flex-shrink-0"
          name="info"
          type="outlined"
          size="md"
        />
      )}
      {children}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'danger']),
  className: PropTypes.string,
  noIcon: PropTypes.bool,
  children: PropTypes.node,
};

export default Alert;
