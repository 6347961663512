/**
 * @key     expect KEY params to add, remove or get from URL search params API.
 *          If the param is an array, string up `[]` with the search key.
 *          example: `key[]`
 *
 * @value   expected to receive STRING as the value pair param value
 *
 * @url     expect to receive STRING as is in the URL
 */

export const addUrlParam = (key, value, url) => {
  const paramUrl = url ?? window.location.href;
  const baseUrl = new URL(paramUrl);
  baseUrl.searchParams.append(key, value);
  return window.history.replaceState(null, null, baseUrl);
};

export const removeUrlParam = (key, url) => {
  const paramUrl = url ?? window.location.href;
  const baseUrl = new URL(paramUrl);
  const params = new URLSearchParams(baseUrl.search);
  params.delete(key);
  return window.history.replaceState(null, null, window.location.pathname);
};

export const getUrlParam = (key, url) => {
  const paramUrl = url ?? window.location.href;
  const baseUrl = new URL(paramUrl);
  return key.includes('[]')
    ? baseUrl.searchParams.getAll(key)
    : baseUrl.searchParams.get(key);
};

export const updateWindowPath = (url) => {
  window.history.pushState({ url }, '', url);
};
