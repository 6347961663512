import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import PageWrapper from '../../components/PageWrapper';

import Input from '../../components/Input';

import Button from '../../components/Button';

import Alert from '../../components/Alert';

import { signInUserRequest } from '../../api/authentication';

import REGEX from '../../lib/constants';

const Authentication = () => {
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (isValid) {
      try {
        await signInUserRequest(data);
      } catch (err) {
        setError(err.error);
      }
    }
  };

  watch(['email', 'password']);

  return (
    <main className="authentication-page">
      <div className="panel">
        <h2 className="poppins-600 color-primary-blue f-24 lh-base text-center text-uppercase">
          Entrar
        </h2>
        {error && (
          <Alert type="danger" noIcon>
            {error}
          </Alert>
        )}
        <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-item">
            <Input
              {...register('email', {
                required: {
                  value: true,
                  message: 'O campo do email é obrigatório',
                },
                pattern: {
                  value: REGEX.EMAIL_FORMAT,
                  message: 'Por favor preencha com um email válido',
                },
              })}
              label="Email"
              placeholder="Inserir email"
              error={errors?.email?.message || error}
              errorMessage={errors?.email?.message}
            />
          </div>
          <div className="login-item mb-3">
            <Input
              {...register('password', {
                required: {
                  value: true,
                  message: 'O campo da password é obrigatório',
                },
              })}
              label="Palavra-passe"
              type="password"
              placeholder="Inserir a palavra-passe"
              error={errors?.password?.message || error}
              errorMessage={errors?.password?.message}
            />
          </div>
          <div className="d-flex flex-column gap-5">
            <Button
              className="align-self-center"
              label="Entrar"
              type="submit"
              submitting={isSubmitting}
            />
            <a href="/users/password/new">
              <p className="poppins-600 f-14 text-uppercase">
                Recuperar palavra-passe
              </p>
            </a>
          </div>
        </form>
      </div>
    </main>
  );
};

export default (props, railsContext) => () => (
  <PageWrapper {...{ ...props, railsContext }}>
    <Authentication {...{ ...props, railsContext }} />
  </PageWrapper>
);
